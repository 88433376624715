import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';
import * as Utils from '../../../../utils/Utils';
import Loading from '../../../UI/Loading';
import { useStyles } from './styles';
import voucherPaymentServicesV2 from '../../../../services/v2/voucherPaymentServicesV2';
import LoadingBilagsky from '../../../UI/LoadingBilagSky';

const STATUS_SIGNANT = {
  WAITING: 2,
  REJECTED: 0,
  ACCEPTED: 1,
};

const ZtlSignPayment = (props) => {
  const { t, history } = props;
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [statusSca, setStatusSca] = useState(STATUS_SIGNANT.WAITING);
  const classes = useStyles();

  const handleSCACallback = async () => {
    setLoading(true);
    try {
      const { flowId } = Utils.getJsonFromUrl();
      if (flowId) {
        const response = await voucherPaymentServicesV2.handleZtlScaCallback({ flowId });
        if (response?.success) {
          const isAccepted = response?.data.some(item => item.status === 1);
          if (isAccepted) {
            setStatusSca(STATUS_SIGNANT.ACCEPTED);
            return
          }
        }
      }
      setStatusSca(STATUS_SIGNANT.REJECTED);
    } catch (error) {
    } finally {
      setLoading(false);
      autoClickCloseModal();
      window?.ReactNativeWebView?.postMessage('success');
      // redirect
      const { pathname } = Utils.getJsonFromUrl();
      // handle redirect link here
      history.push(pathname)
    }
  };

  const autoClickCloseModal = () => {
    // setTimeLeft(3);
    setTimeout(() => {
      // close button
      const {
        parent: { document },
      } = window;
      const btnClose = document.querySelectorAll('.modal-close-icon');
      if (btnClose && btnClose[0]) btnClose[0].click();
      // close popup
      window.close();
    }, 0);
  };

  const renderSCAByStatus = () => {
    let statusTexts = [];
    statusTexts[STATUS_SIGNANT.ACCEPTED] = <span className={classes.accepted}>{t('ztl:payment:sca:accepted')}</span>
    statusTexts[STATUS_SIGNANT.REJECTED] = <span className={classes.rejected}>{t('ztl:payment:sca:rejected')}</span>
    return statusTexts[statusSca] || '';
  };

  useEffect(() => {
    handleSCACallback();
  }, []);

  // useEffect(() => {
  //   if (timeLeft === 0) {
  //     setTimeLeft(null)
  //   }
  //   // exit early when we reach 0
  //   if (!timeLeft) return;
  //   // save intervalId to clear the interval when the
  //   // component re-renders
  //   const intervalId = setInterval(() => {
  //     setTimeLeft(timeLeft - 1);
  //     if (timeLeft === 1) {
  //       const { pathname } = Utils.getJsonFromUrl();
  //       // handle redirect link here
  //       history.push(pathname)
  //     }
  //   }, 1000);
  //   // clear interval on re-render to avoid memory leaks
  //   return () => clearInterval(intervalId);
  // }, [timeLeft]);

  return (
    <div className={classes.container}>
      <LoadingBilagsky loading={loading || statusSca === STATUS_SIGNANT.WAITING} />
      {renderSCAByStatus()}
      {/* <hr />
      {timeLeft
        ? <div className='d-flex red-text center'>( {timeLeft} )</div>
        : ''} */}
    </div>
  );
};

export default withNamespaces('ztl')(ZtlSignPayment);
