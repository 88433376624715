import * as types from '../actionTypes/ActionTypes';

const filters = {
  voucher: {
    currentSort: 1,
    pageNumber: 1,
    pageSize: 10,
    sortBy: 'Voucher.Date',
    sortType: 'desc',
    isLoading: false,
    keyword: '',
    status: 0,
    connectStatus: '',
  },
};

const initialState = {
  filters,
  transactions: null,
  vouchers: {
    rows: [],
    total: 0,
  },
  loading: {
    voucher: false,
    transaction: false,
  },
};

const connectMissing = (state = initialState, action) => {
  switch (action.type) {
    case types.CONNECT_MISSING.SET_CONNECT_MISSING_BANK:
      return {
        ...state,
        transactions: action.payload,
      };
    case types.CONNECT_MISSING.SET_CONNECT_MISSING_VOUCHER:
      return {
        ...state,
        vouchers: action.payload,
      };
    case types.CONNECT_MISSING.GET_CONNECT_MISSING_VOUCHER_REQUEST:
      return {
        ...state,
        loading: {
          voucher: true,
        },
      };
    case types.CONNECT_MISSING.GET_CONNECT_MISSING_VOUCHER_SUCCESS:
      return {
        ...state,
        vouchers: action.payload,
        loading: {
          voucher: false,
        },
      };
    case types.CONNECT_MISSING.REMOVE_RECORD_VOUCHER:
      return {
        ...state,
        vouchers: { rows: state.vouchers.rows.filter((v) => v.Id !== +action.payload), total: state.vouchers.total - 1 },
        loading: {
          voucher: false,
        },
      };
    case types.CONNECT_MISSING.GET_CONNECT_MISSING_VOUCHER_FAIL:
      return {
        ...state,
        loading: {
          voucher: false,
        },
      };
    default:
      return state;
  }
};

export default connectMissing;
