import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import timeHelper from '../../../helpers/timeHelper';
import * as selectors from '../../../selectors';
import VoucherServices from '../../../services/voucherServices';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import voucherServicesV2 from '../../../services/v2/voucherServicesV2';

export default function* fetchVoucherDataSaga() {
  yield takeEvery(actionTypes.VOUCHER.FETCH_VOUCHER_DATA_REQUEST, fetchVoucherData);
}

export function* fetchVoucherData(action) {
  try {
    const company = yield select(selectors.company);
    const { CompanyId: companyId } = company;

    if (!companyId) {
      yield put(actions.fetchVoucherDataFail('Company ID is missing'));
      return;
    }

    // get and set years data 
    const yearsData = yield call(VoucherServices.getYears, companyId);

    if (!yearsData.success) {
      yield put(actions.fetchVoucherDataFail('Failed to fetch years data'));
      return;
    }

    if (yearsData?.success) {
      yield put(actions.setVoucherYears(yearsData.data));
    }

    // get and set filter voucher
    const sfilter = yield select(selectors.filterVoucher);
    const filter = { ...sfilter, companyId };

    if (localStorage.getItem('voucherYear') && yearsData.data.map((e) => e.value).includes(+localStorage.getItem('voucherYear')) && Number.isFinite(+localStorage.getItem('voucherYear')) && !filter.voucherId) {
      filter.year = localStorage.getItem('voucherYear');
    }

    if (action.payload?.isInit) {
      if (filter.voucherId) {
        const responseVoucher = yield call(VoucherServices.fetchAll, filter);
        if (responseVoucher.success) {
          const { data: { rows = [] } } = responseVoucher;
          if (rows.length === 1) {
            const voucherYear = timeHelper.formatDate(rows[0].Date, 'YYYY');
            filter.year = filter.year ?? voucherYear;
            localStorage.setItem('voucherYear', filter.year);
            yield put(actions.setCurrentVoucher(rows[0]));
          }
        }
      }
    } else {
      if (action.payload?.replace && action.payload?.history?.location?.search?.includes('?')) {
        action.payload?.history.push(location.pathname);
      }
      filter.voucherId = null;
      yield put(actions.setCurrentVoucher({}));
    }

    filter.year = filter.year || (filter.year === null && filter.year !== '' ? yearsData.data[0].value : '');

    if (Number.isFinite(filter.month)) {
      filter.from = null;
      filter.to = null;
    }

    delete filter.years;

    const [voucherStatisticDataResponse, voucherListDataResponse] = yield all([
      call(VoucherServices.getStatistic, filter),
      call(voucherServicesV2.fetchAll, { ...filter, withCountPayment: 1 }) // update fetch count payment on change to use fetchAll voucher v2
    ]);

    const data = {
      voucherStatisticData: voucherStatisticDataResponse.success ? voucherStatisticDataResponse.data : { statis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
      voucherListData: voucherListDataResponse.success ? voucherListDataResponse.data : { total: 0, rows: [] }
    };

    yield put(actions.fetchVoucherDataSuccess(data));
    yield put(actions.setFilterVoucher(filter));
  } catch (error) {
    console.error(error);
    yield put(actions.fetchVoucherDataFail(error));
  }
}