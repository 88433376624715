import * as types from '../actionTypes/ActionTypes';

const voucherYears = [];

const initFilter = {
  // years: null,
  year: null,
  month: 0,
  from: '',
  to: '',
  sortBy: 'Id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 50,
  keyword: '',
  source: -1,
  status: -1,
  voucherId: null,
};

const filter = { ...initFilter };

// INIT
const initialState = {
  isShowBalance: false,
  voucherStatisticData: {
    statis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  voucherBalance: [],
  voucherDetail: {},
  voucherListData: null,
  adminVoucherData: {
    total: 0,
    rows: [],
  },
  loading: {
    statistic: false,
    voucher: false,
    voucherBalance: false,
    voucherDetail: false,
    missingSummary: false,
    adminVoucher: false,
    voucherBalances: false
  },
  currentVoucher: null,
  filter,
  missingSummaryData: {},
  isOpenWindowVoucher: false,
  voucherSuggestInfo: {},
  voucherLabels: [],
  voucherBalancesData: {
    voucherBalances: [],
    rest: 0,
  },
  voucherYears
};

const voucher = (state = initialState, action) => {
  switch (action.type) {
    case types.VOUCHER.SET_VOUCHER_YEARS:
      return {
        ...state,
        voucherYears: action.payload,
      };
    case types.VOUCHER.SET_CURRENT_VOUCHER:
      return {
        ...state,
        currentVoucher: action.payload,
      };
    case types.VOUCHER.REMOVE_ROW_VOUCHER:
      return {
        ...state,
        loading: {
          statistic: false,
          voucher: false,
        },
        voucherListData: {
          ...state.voucherListData,
          rows: action.payload,
        },
      };
    case types.VOUCHER.SET_FILTER_VOUCHER:
      return {
        ...state,
        filter: action.payload,
      };
    case types.VOUCHER.RESET_FILTER:
      return {
        ...state,
        filter: initFilter,
      };
    case types.VOUCHER.SET_VALUE_RECORD_TRANSACTION:
      return {
        ...state,
        voucherListData: {
          ...state.voucherListData,
          rows: state.voucherListData.rows.map((v) => {
            if (v.Id === action.payload.Id) {
              v = action.payload.value;
            }
            return v;
          }),
        },
      };
    case types.VOUCHER.FETCH_VOUCHER_DATA_REQUEST:
      return {
        ...state,
        loading: {
          statistic: true,
          voucher: true,
        },
      };
    case types.VOUCHER.FETCH_VOUCHER_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          statistic: false,
          voucher: false,
        },
        voucherStatisticData: action.payload.voucherStatisticData,
        voucherListData: action.payload.voucherListData,
      };
    case types.VOUCHER.FETCH_VOUCHER_DATA_FAIL:
      return {
        ...state,
        loading: {
          statistic: false,
          voucher: false,
        },
      };
    case types.VOUCHER.SET_VOUCHER_LIST_DATA:
      return {
        ...state,
        voucherListData: action.payload,
      };
    case types.VOUCHER.GET_VOUCHER_BALANCE_REQUEST:
      return {
        ...state,
        loading: {
          voucherBalance: true,
        },
      };
    case types.VOUCHER.GET_VOUCHER_BALANCE_SUCCESS:
      return {
        ...state,
        voucherBalance: action.payload,
        loading: {
          voucherBalance: false,
        },
      };
    case types.VOUCHER.GET_VOUCHER_BALANCE_FAIL:
      return {
        ...state,
        loading: {
          voucherBalance: false,
        },
      };
    case types.VOUCHER.GET_MISSING_SUMMARY_REQUEST:
      return {
        ...state,
        loading: {
          missingSummary: true,
        },
      };
    case types.VOUCHER.GET_MISSING_SUMMARY_SUCCESS:
      return {
        ...state,
        missingSummaryData: action.payload,
        loading: {
          missingSummary: false,
        },
      };
    case types.VOUCHER.GET_MISSING_SUMMARY_FAIL:
      return {
        ...state,
        loading: {
          missingSummary: false,
        },
      };
    case types.VOUCHER.SET_OPEN_WINDOW_VOUCHER:
      return {
        ...state,
        isOpenWindowVoucher: action.payload,
      };
    case types.VOUCHER.FETCH_ADMIN_VOUCHER_REQUEST:
      return {
        ...state,
        loading: {
          adminVoucher: true,
        },
      };
    case types.VOUCHER.FETCH_ADMIN_VOUCHER_SUCCESS:
      return {
        ...state,
        adminVoucherData: action.payload,
        loading: {
          adminVoucher: false,
        },
      };
    case types.VOUCHER.FETCH_ADMIN_VOUCHER_FAIL:
      return {
        ...state,
        loading: {
          adminVoucher: false,
        },
      };
    case types.VOUCHER.GET_VOUCHER_DETAIL_REQUEST:
      return {
        ...state,
        loading: {
          voucherDetail: true,
        },
      };
    case types.VOUCHER.GET_VOUCHER_DETAIL_SUCCESS:
      return {
        ...state,
        voucherDetail: action.payload,
        loading: {
          voucherDetail: false,
        },
      };
    case types.VOUCHER.GET_VOUCHER_DETAIL_FAIL:
      return {
        ...state,
        loading: {
          voucherDetail: false,
        },
      };
    case types.VOUCHER.GET_VOUCHER_SUGGEST_INFO:
      return {
        ...state,
        loading: {
          voucherSuggestInfo: true,
        },
      };
    case types.VOUCHER.GET_VOUCHER_SUGGEST_INFO_SUCCESS:
      return {
        ...state,
        voucherSuggestInfo: action.payload,
        loading: {
          voucherSuggestInfo: false,
        },
      };
    case types.VOUCHER.GET_VOUCHER_SUGGEST_INFO_FAIL:
      return {
        ...state,
        loading: {
          voucherSuggestInfo: false,
        },
      };
    case types.VOUCHER.SET_VOUCHER_LABELS:
      return {
        ...state,
        voucherLabels: action.payload,
      };
    case types.VOUCHER.GET_VOUCHER_BALANCES_V2_REQUEST:
      return {
        ...state,
        loading: {
          voucherBalances: true,
        },
      };
    case types.VOUCHER.GET_VOUCHER_BALANCES_V2_SUCCESS:
      return {
        ...state,
        voucherBalancesData: action.payload,
        loading: {
          voucherBalances: false,
        },
      };
    case types.VOUCHER.GET_VOUCHER_BALANCES_V2_FAIL:
      return {
        ...state,
        loading: {
          voucherBalances: false,
        },
      };

    default:
      return state;
  }
};

export default voucher;
