import { call, put, takeEvery } from 'redux-saga/effects';
import orytonServices from '../../../services/orytonServices';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';

export default function* getCompaniesSaga() {
  yield takeEvery(actionTypes.COMPANY.GET_COMPANIES_REQUEST, getCompanies);
}

export function* getCompanies(action) {
  try {
    const response = yield call(orytonServices.getCompanyByUser, action.payload);
    const ownResponse = yield call(orytonServices.getOwnCompanys);
    const dataOwner = filterAndMapOwnCompanies(ownResponse?.data);
    const { success, data, message } = response;
    if (success) {
      if (action.callback) {
        action.callback(data.data, actions.getCompaniesSuccess({ total: data.total, data: data.data, dataOwner }));
      }
    } else {
      yield put(actions.getCompaniesFail(message));
    }
  } catch (error) {
    yield put(actions.getCompaniesFail(error));
  }
}

// Extracted function for filtering and mapping own companies
const filterAndMapOwnCompanies = (ownCompanies) => {
  if (!ownCompanies || ownCompanies.length === 0) {
    return [];
  }

  return ownCompanies
    .filter((company) => ([undefined, null].includes(company.SubscriptionId) || company.Status === 0) && company.IsSpecial !== 1)
    .map((company) => ({
      ...company,
      CompanyId: company.Id,
      isOwner: true,
    }));
};