import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
// import VoucherServices from '../../../services/voucherServices';
import voucherServicesV2 from '../../../services/v2/voucherServicesV2';

export default function* getConnectMissingVoucher() {
  yield takeEvery(actionTypes.CONNECT_MISSING.GET_CONNECT_MISSING_VOUCHER_REQUEST, getConnectMissingVoucherSaga);
}

export function* getConnectMissingVoucherSaga(action) {
  try {
    const response = yield call(voucherServicesV2.fetchAll, action.payload);
    if (response.success) {
      yield put(actions.getConnectMissingVoucherSuccess(response.data));
    } else {
      yield put(actions.getConnectMissingVoucherFail(response.data));
    }
  } catch (error) {
    yield put(actions.getConnectMissingVoucherFail(error));
  }
}
