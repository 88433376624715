import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import * as Utils from '../../../../utils/Utils';
import { useStyles } from './styles';
import voucherPaymentServicesV2 from '../../../../services/v2/voucherPaymentServicesV2';
import LoadingBilagsky from '../../../UI/LoadingBilagSky';
import { RoutesUrl } from '../../../../constants/routes.constants';

const STATUS_SIGNANT = {
  WAITING: 2,
  REJECTED: 0,
  ACCEPTED: 1,
};

const ZtlCancelPayment = (props) => {
  const { t } = props;
  const [loading, setLoading] = useState(false);
  const [statusSca, setStatusSca] = useState(STATUS_SIGNANT.WAITING);
  const classes = useStyles();

  const handleSCACallback = async () => {
    try {
      setLoading(true);
      const { flowId, paymentId } = Utils.getJsonFromUrl();
      if (flowId && paymentId) {
        const response = await voucherPaymentServicesV2.handleZtlCancelCallback({ flowId, paymentId });
        if (response?.success) {
          return setStatusSca(STATUS_SIGNANT.ACCEPTED);
        }
      }
      setStatusSca(STATUS_SIGNANT.REJECTED);
    } catch (error) {
    } finally {
      const { alias } = Utils.getJsonFromUrl();
      setLoading(false);
      // autoClickCloseModal();
      window?.ReactNativeWebView?.postMessage('success');
      // redirect
      setTimeout(() => {
        if (alias) {
          const { origin } = window.location;
          const redirectUrl = origin + RoutesUrl.PaymentList.replace(':company', alias);
          window.location.href = redirectUrl;
        }
      }, 3000);
    }
  };

  const renderSCAByStatus = () => {
    let statusTexts = [];
    statusTexts[STATUS_SIGNANT.ACCEPTED] = <div className={classes.accepted}>{t('ztl:consent:statuses:accepted')}</div>
    statusTexts[STATUS_SIGNANT.REJECTED] = <div className={classes.rejected}>{t('ztl:consent:statuses:rejected')}</div>
    return statusTexts[statusSca];
  };

  useEffect(() => {
    handleSCACallback();
  }, []);

  return (
    <div className={classes.container}>
      {loading && <LoadingBilagsky loading={loading} />}
      {renderSCAByStatus()}
    </div>
  );
};

export default withNamespaces('ztl')(ZtlCancelPayment);
